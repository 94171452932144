import {
  createContext,
  FC,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from "react";
import { redirect, useNavigate } from "react-router-dom";
import { Me } from "types";
import marketingTools from "utils/marketingTools";
import { getJwtToken, setJwtToken } from "utils/storage";


interface AuthContextInterface {
  isAuthenticated: boolean;
  token: string | null;
  me: Me | null;
  logIn: (token: string, me: Me | null) => void;
  logOut: () => void;
  setToken: (token: string | null) => void;
  setMe: (me: Me | null) => void;
}

export const AuthContext = createContext<AuthContextInterface>({
  isAuthenticated: !!getJwtToken(),
  token: getJwtToken(),
  me: null,
  logIn: () => {},
  logOut: () => {},
  setToken: () => {},
  setMe: () => {},
});

// Define the type for the children prop
type AuthProviderProps = {
  children: ReactElement;
};

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState<string | null>(getJwtToken());
  const [me, setMe] = useState<Me | null>(null);
  const navigate = useNavigate();

  const logIn = useCallback((token: string | null, me: Me | null) => {
    if (token) {
      setJwtToken(token);
      setToken(token);
      setMe(me);

      marketingTools.hasLogin();
      marketingTools.identifyUser(me as Me);
    } else {
      setJwtToken("");
      setToken(null);
      setMe(null);

      marketingTools.unidentifyUser();
    }
  }, []);

  const logOut = useCallback(async () => {
    setJwtToken("");
    setToken(null);
    setMe(null);
    marketingTools.unidentifyUser();
  }, []);

  const value = useMemo<AuthContextInterface>(
    () => ({
      isAuthenticated: !!token,
      token,
      me,
      logIn,
      logOut,
      setToken,
      setMe,
    }),
    [token, me, logIn, logOut, setMe, setToken]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
